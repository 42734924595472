/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */
import React, { PureComponent } from 'react';
import {
  LineChart,
  AreaChart,
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, stroke, value } = this.props;

    return (
      <text x={x} y={y} dy={-5} fill={stroke} fontSize={10} textAnchor="middle">
        ${value}
      </text>
    );
  }
}

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={0}
          textAnchor="end"
          fill="#666"
          transform="rotate(-90)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

export default class StatementLineChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: props.monthlyBillingHistory,
    };
  }

  render() {
    return (
      <LineChart
        width={345}
        height={188}
        data={this.state.data}
        margin={{
          top: 15,
          right: 15,
          left: 15,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 1" />
        <XAxis dataKey="date" tick={<CustomizedAxisTick />} interval={0}/>
        <Line
          type="linear"
          dataKey="amount"
          stroke="#8895A7"
          fill="#00767C"
          isAnimationActive={false}
          label={<CustomizedLabel />}
        />
      </LineChart>
    );
  }
}
