import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

class CustomizedLabel extends PureComponent {
  render() {
    const { x, y, fill, value } = this.props;
    return (
      <text
        x={x + 8}
        y={y}
        dy={-1}
        fontSize="16"
        fontFamily="sans-serif"
        fill={fill}
        textAnchor="middle"
      >
        ${value}
      </text>
    );
  }
}
// class CustomizedAxisTick extends PureComponent {
//   render() {
//     const { x, y, stroke, payload } = this.props;
//
//     return (
//         <g transform={`translate(${x},${y})`}>
//           <text
//               x={0}
//               y={0}
//               dy={3}
//               textAnchor="end"
//               fill="#666"
//               transform="rotate(-15)"
//           >
//             {payload.value}
//           </text>
//         </g>
//     );
//   }
// }

export default class StatementBarChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: props.residentPortion,
    };
  }

  render() {
    return (
      <BarChart
        width={345}
        height={188}
        data={this.state.data}
        margin={{
          top: 15,
          right: 5,
          left: 5,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 1" />
        {/*<XAxis dataKey="utilityTypeName" tick={<CustomizedAxisTick />} interval={0}/>*/}
        <XAxis dataKey="utilityTypeName" interval={0}/>
        <Bar
          dataKey="propertyTotal"
          isAnimationActive={false}
          fill="#B8C4CE"
          label={<CustomizedLabel />}
          barSize={20}
        />
        <Bar
          dataKey="residentPortion"
          isAnimationActive={false}
          fill="#00767C"
          label={<CustomizedLabel />}
          barSize={20}
        />
      </BarChart>
    );
  }
}
