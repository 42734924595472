import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import StatementV2 from "./templates/StatementV2";
import AppfolioStatement from "./templates/AppfolioStatement";
import ResidentInvite from "./templates/ResidentInvite";

const App = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [featureFlags, setFeatureFlags] = useState({});
  const getFlagIsEnabled = (flagName) => {
    return featureFlags[`${flagName}`];
  };
  const getFeatureFlags = async () => {
    setIsLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/account/api/featureflags`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "GET",
      }
    );
    const responseData = await response.json();
    if (responseData?.isSuccessful) {
      await setFeatureFlags(responseData?.response);
    }

    setIsLoading(false);
    return responseData;
  };

  useEffect(() => {
    getFeatureFlags();
  }, []);

  return isLoading ? (
    <div>Loading</div>
  ) : (
    <div>
      <Routes>
        <Route
          exact
          path="/"
          element={<div>Paper correspondence templates</div>}
        />
        <Route
          exact
          path="/statement"
          element={<StatementV2 getFlagIsEnabled={getFlagIsEnabled} />}
        />
        <Route
          exact
          path="/appfolio-statement"
          element={<AppfolioStatement getFlagIsEnabled={getFlagIsEnabled} />}
        />
        <Route
          exact
          path="/resident-invite"
          element={<ResidentInvite getFlagIsEnabled={getFlagIsEnabled} />}
        />
      </Routes>
    </div>
  );
};
export default App;
