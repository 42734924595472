import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import logo from './images/logo.png';
import logoBlack from './images/logo-black.png';
import { Icon, getUtility } from '@livable/saas-react-components';
import welcome from './images/welcome.svg';
import './styles.css';

const ResidentInvite = () => {
  const BillFrequencyType = {
    Monthly: 'Monthly',
    BiMonthly: 'Bi-Monthly',
    Trimonthly: 'Trimonthly',
    Annually: 'Annually',
  };
  const AllocationMethods = {
    ByOccupancy: 'Occupancy',
    ByUnit: 'Unit',
    BySquareFootage: 'Square footage',
    ByPercentageShare: 'Percentage share',
    BySubmeter: 'Submeter',
  };

  let [isError, setIsError] = useState(false);
  let [isSuccess, setIsSuccess] = useState(false);
  let [isLoading, setIsLoading] = useState(true);
  let [queryParams, setQueryParams] = useSearchParams();
  let [resultUtilities, setResultUtilities] = useState([]);
  let [propertyAddress, setPropertyAddress] = useState('');
  let [residentName, setResidentName] = useState('');
  let [invitedByName, setInvitedByName] = useState('');

  const getIsPaperLetter = () => {
    return queryParams.get('isPaperLetter').toLowerCase() === 'true';
  };

  const login = async (email, password, companyId) => {
    const loginResponse = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/account/api/admin/login`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          email: email,
          password: password,
          companyId: companyId,
        }),
      }
    );
    const loginData = await loginResponse.json();
    return loginData;
  };

  const getResidencyBillingSettings = async ({ accessToken, residencyId }) => {
    const residencyBillingSettingsResponse = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/property/api/residencies/${residencyId}/billingsettings`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }
    );
    const response = await residencyBillingSettingsResponse.json();
    return response;
  };

  const getProperty = async ({ accessToken, propertyId }) => {
    const propertyResponse = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/property/api/properties/${propertyId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }
    );
    const response = await propertyResponse.json();
    return response;
  };

  const getResidencyOverviewByResidencyId = async ({
    accessToken,
    residencyId,
  }) => {
    const residencyOverviewByResidencyId = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/property/api/residencies/${residencyId}/overview`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }
    );
    const response = await residencyOverviewByResidencyId.json();
    return response;
  };

  const getPropertyUtilityProviders = async ({ accessToken, propertyId }) => {
    const propertyUtilityProviders = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/property/api/properties/${propertyId}/utilityproviders`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'GET',
      }
    );
    const response = await propertyUtilityProviders.json();
    return response;
  };

  const getUsersProfiles = async ({ accessToken, accountIds }) => {
    const usersProfiles = await fetch(
      `${process.env.REACT_APP_API_GATEWAY_BASE_URL}/account/api/account/profiles`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          accountIds: accountIds,
        }),
      }
    );
    const response = await usersProfiles.json();
    return response;
  };

  const handleFetchData = async () => {
    const reportUserEmail = queryParams.get('reportUserEmail');
    const reportUserPassword = queryParams.get('reportUserPassword');
    const companyId = queryParams.get('companyId');
    const residencyId = queryParams.get('residencyId');
    const invitedByAccountId = queryParams.get('invitedByAccountId');

    try {
      setIsError(false);
      const loginData = await login(
        reportUserEmail,
        reportUserPassword,
        companyId
      );

      const accessToken = loginData.response.accessToken;

      const residencyBillingSettingsResp = await getResidencyBillingSettings({
        accessToken,
        residencyId,
      });

      const residencyOverviewByResidencyIdResp =
        await getResidencyOverviewByResidencyId({
          accessToken,
          residencyId,
        });

      const propertyId =
        residencyOverviewByResidencyIdResp?.response?.propertyId;
      const residentAccountId =
        residencyOverviewByResidencyIdResp?.response?.residentAccountId;

      const usersProfilesResp = await getUsersProfiles({
        accessToken,
        accountIds: [invitedByAccountId, residentAccountId],
      });

      const residentAccountProfile = usersProfilesResp.response.find(
        (i) => i.id === residentAccountId
      );
      const invitedByAccountProfile = usersProfilesResp.response.find(
        (i) => i.id === invitedByAccountId
      );

      setResidentName(
        `${residentAccountProfile.firstName} ${residentAccountProfile.lastName}`
      );

      setInvitedByName(
        `${invitedByAccountProfile.firstName} ${invitedByAccountProfile.lastName}`
      );

      const propertyResp = await getProperty({
        accessToken,
        propertyId,
      });

      setPropertyAddress(propertyResp.response.propertyAddress);

      const propertyUtilityProvidersResp = await getPropertyUtilityProviders({
        accessToken,
        propertyId,
      });

      const allUtilities = [];

      propertyUtilityProvidersResp.response.map((i) => {
        i.utilities.map((current) => {
          allUtilities.push(current);
        });
      });

      const resultUtilities = [];

      residencyBillingSettingsResp.response.residencyUtilities.map((i) => {
        const currentUtility = allUtilities.find(
          (e) => e.utilityId === i.utilityId
        );
        resultUtilities.push({
          id: currentUtility.id,
          utilityType: currentUtility.utilityType,
          utilityTitle: currentUtility.utilityType,
          billFrequencyType:
            BillFrequencyType[currentUtility.billFrequencyType],
          allocationMethod:
            AllocationMethods[currentUtility.allocationMethod],
          commonAreaDeduction: `${currentUtility.commonAreaDeduction}%`,
        });
      });

      residencyBillingSettingsResp.response.residencyUtilityOptOuts.map((i) => {
        const currentUtility = allUtilities.find(
          (e) => e.utilityId === i.utilityId
        );
        resultUtilities.push({
          id: currentUtility.id,
          utilityType: currentUtility.utilityType,
          utilityTitle: `${currentUtility.utilityType} (Opted out)`,
          billFrequencyType:
            BillFrequencyType[currentUtility.billFrequencyType],
          allocationMethod:
            AllocationMethods[currentUtility.allocationMethod],
          commonAreaDeduction: `${currentUtility.commonAreaDeduction}%`,
        });
      });

      setResultUtilities(resultUtilities);
      setIsSuccess(true);
      setIsLoading(false);
    } catch (e) {
      setIsSuccess(false);
      setIsError(true);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div
      className={`resident-invite ${
        getIsPaperLetter() ? 'resident-invite__isPaper' : ''
      }`}
    >
      {isSuccess && <div id="successfullReport" />}
      {isError && <div id="failedReport" />}
      <div id="resident-name-output" style={{ display: 'none' }}>
        {residentName}
      </div>
      <div className="pdf-wrapper">
        <div className="content">
          {isLoading && <div className="generating">Data generation...</div>}
          {!isLoading && (
            <>
              <div className="section">
                <img src={logo} alt="logo" className="logo show-e" />
                <img src={logoBlack} alt="logo" className="logo show-paper" />
                <div className="top-sections">
                  <div className="top-left-block">
                    <div className="lob-address"></div>
                    <img src={welcome} className="drawing" alt="welcome" />
                  </div>
                  <div className="top-right-block">
                    <h3>Hello, {residentName}</h3>
                    <h5 className="xs-spacing">
                      Welcome to Livable! Your Property Manager {' '}
                      {/*Welcome to Livable! Your Property Manager {invitedByName}{' '}*/}
                      has invited you to join your community on the Livable
                      platform.
                    </h5>
                    <p className="xs-spacing">
                      By signing up for online portal access, Livable provides a
                      single place for you to view and pay your fair share of
                      your community’s utility bills, along with a full billing
                      history. In addition to easy ways to pay, you’ll also see
                      simple tips you can use to reduce your consumption - and
                      your bill. What’s good for the planet is good for your
                      wallet!
                    </p>{' '}
                  </div>
                </div>
              </div>

              <div className="section section-big">
                <h5 className="md-spacing">Your current setup:</h5>
                <h4 className="xxs-spacing bold primary">
                  Address: {propertyAddress}
                </h4>
                <div className="xs-spacing">
                  <table
                    width="100%"
                    cellPadding="0"
                    cellSpacing="0"
                    className="utility-table"
                  >
                    <thead>
                      <tr>
                        <td valign="top">Utility enrollment:</td>
                        <td valign="top">Billing method:</td>
                        <td valign="top">Billing frequency:</td>
                        <td valign="top" align="right">
                          Common Area Deductions*:
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {resultUtilities.map((i) => {
                        return (
                          <tr key={i.id}>
                            <td valign="top">
                              <span className="icon">
                                {getUtility(i?.utilityType).icon()}
                              </span>
                              <span>{i.utilityTitle}</span>
                            </td>
                            <td valign="top">{i.allocationMethod}</td>
                            <td valign="top">{i.billFrequencyType}</td>
                            <td valign="top" align="right">
                              {i.commonAreaDeduction}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <p className="italic xs-spacing small-text light-text">
                  *The Common Area Deduction (CAD) represents utilities that are
                  used by common areas of the property such as elevators, lobby
                  lighting, common irrigation, shared laundry or a pool.
                  Ownership absorbs this portion of the bill.
                </p>{' '}
                <p className="lg-spacing">
                  To finish setting up your account, contact your Property
                  Manager with your email address and request access to the
                  online portal.
                </p>
                <p className="xs-spacing">Happy saving!</p>
              </div>

              <div className="section">
                <div className="footer">
                  <div>
                    <h5>Need help:</h5>
                    <p className="xs-spacing">
                      <span className="icon">
                        <Icon type="mail" />
                      </span>
                      Email: help@livable.com
                    </p>
                    <p className="xs-spacing">
                      <span className="icon">
                        <Icon type="livable-inner" />
                      </span>
                      Website: https://www.livable.com/
                    </p>
                    <p className="xs-spacing">
                      <span className="icon">
                        <Icon type="facebook" />
                      </span>
                      Facebook: https://www.facebook.com/comesavewithlivable
                    </p>
                    <p className="xs-spacing">
                      <span className="icon">
                        <Icon type="linkedin" />
                      </span>
                      Linkedin: https://www.linkedin.com/company/livablesaves
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResidentInvite;
